.gallery-photo__content {
    background: #33bdff00;
}

.grid-container {
    columns: 3 200px;
    column-gap: 1.5rem;
    width: 90%;
    margin: 0 auto;
    div {
      width: 150px;
      margin: 0 1.5rem 1.5rem 0;
      display: inline-block;
      width: 100%;
      border: solid 2px black;
      padding: 5px;
      box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
      border-radius: 5px;
      transition: all .25s ease-in-out;
      &:hover img {
        filter: grayscale(0);
      }
      &:hover {
        border-color: coral;
      }
      img {
        width: 100%;
        filter: grayscale(100%);
        border-radius: 5px;
        transition: all .25s ease-in-out;
      }
      p {
        margin: 5px 0;
        padding: 0;
        text-align: center;
        font-style: italic;
      }
    }
  }