li{
    list-style: none;
    line-height: 32px;
}
span{
    padding-right: 10px;
}  
  .form-box {
    max-width: 500px;
    padding: 45px;
  }  
  input, textarea {
    width: 100%;
  }
  p{
    text-align: justify;
  }
  .card{
    color: #1C1F23;
  }
  .icon{
    color: #264495;
  }

.pattern {
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; 
}

.background-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.5; 
}

.container {
  position: relative;
}

.theme-btn:after {
  top: 50%;
  left: 50%;
  content: "";
  width: 100%;
  height: 0%;
  z-index: -1;
  position: absolute;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.global-country {
  margin-left: 25px;
  margin-right: 22px;
  margin-top: -5rem;
  display: inline-block;
  background-size: auto;
  padding: 8.8px 31.5px;
  background-color: #33BDFF;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0px 0px 20px 0px;
  /* background-image: url('\assets\img\bg_04.png'); */
}

.for{
  background: linear-gradient(to left, rgb(255, 255, 255), #33beffd8);
}
/* h4{
  color: #FFBD59;
} */

/* .section {
  background-image: url('/assets/img/team-4.jpg');
  background-size: cover;
  color: #ffffff;
  padding: 100px 0;
  text-align: center;
} */

/* .button {
  background-color: #fd9a01;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #ffbf00;
} */